$primary_text_color: #444;
$secondary_text_color: #999;
$masthead_secondary_text_color: $secondary_text_color;

html, button, input, select, textarea,
.pure-g [class *= "pure-u"] {
  font: 300 16px/1.9em "ProximaNovaLight", "Helvetica Neue", Helvetica, sans-serif;
  font-family: "Roboto", sans-serif !important;
  color: $primary_text_color;
}

body {
  padding-bottom: 80px;
}

h1, h2, h3, h4, h5, h6 {
  /*font-family: "Francois One", sans-serif;*/
  /*font-family: "ProximaNovaLight", "Helvetica Neue", Helvetica, sans-serif;*/
  letter-spacing: normal;
}

hr {
  margin: 1.5em 0;
  background: none;
  height: 0;
  border: 0 solid #efefef;
  border-top-width: 1px;
}

a {
  color: #0e69a1;
  text-decoration: none;

  &:hover {
    color: #09466E;
  }
}

blockquote {
  margin-left: 0;
  margin-right: 0;
  padding: 10px;
  border-left: 7px solid #f3f3f3;
  background: #f9f9f9;
  font-size: 90%;

  p {
    margin: 0;
    padding: 0;
  }
}

@mixin fancy-hr ($bg: #fff, $dark: #c3c3c3, $light: #f7f6f4) {
  height: 2px;
  border: 0;
  background-color: $dark;
  background-image: -webkit-gradient(linear, 0 0, 100% 0, from($bg), to($bg), color-stop(50%, $light));
  background-image: -webkit-linear-gradient(left, $bg, $light, $bg);
  background-image: -moz-linear-gradient(left, $bg, $light, $bg);
  background-image: -ms-linear-gradient(left, $bg, $light, $bg);
  background-image: -o-linear-gradient(left, $bg, $light, $bg);

  &::after {
    content: '';
    display: block;
    height: 1px;
    background-color: $light;
    background-image: -webkit-gradient(linear, 0 0, 100% 0, from($bg), to($bg), color-stop(50%, $dark));
    background-image: -webkit-linear-gradient(left, $bg, $dark, $bg);
    background-image: -moz-linear-gradient(left, $bg, $dark, $bg);
    background-image: -ms-linear-gradient(left, $bg, $dark, $bg);
    background-image: -o-linear-gradient(left, $bg, $dark, $bg);
  }
}


//////////////////////////////  Mast head  //////////////////////////////

.masthead {
  h2 {
    font-weight: normal;
    text-transform: uppercase;

    a {
      color: $primary_text_color;
      text-decoration: none;
    }
  }

  h3 {
    color: $masthead_secondary_text_color;
    font-weight: normal;
    text-transform: uppercase;
    letter-spacing: normal;

    a {
      color: $masthead_secondary_text_color;
      text-decoration: none;
    }
  }
}


.masthead-lg {
  text-align: center;
  color: #444;
  padding: 4em 0;

  /* Adapted from http://www.daveperrett.com/articles/2013/04/07/recreating-the-reeder-two-tone-horizontal-rule-in-pure-css/ */
  hr {
    margin: 3em auto;
    width: 30%;
    min-width: 300px;
    //@include fancy-hr(#fff);
  }

  img {
    border-radius: 50%;
    border: 1px solid rgba(0, 0, 0, 0.1);
  }

  ul, li {
    display: inline;
    margin: 0;
    padding: 0;
  }

  a {
    background: #fff;
    border-radius: 50%;
    opacity: 0.4;
    color: #999;
    display: inline-block;
    width: 32px;
    height: 32px;
    font-size: 16px;
    transition-duration: 0.2s;
    transition-property: background, color;

    &:hover {
      background: #dfdfdf;
      color: #444;
    }
  }

  .fa {
    line-height: 32px;
  }

  h2 {
    font-size: 2.25rem;
  }

  h3 {
    font-size: 0.9em;
  }
}

.masthead-sm {
  padding: 1rem 0;
  border: 0 solid #efefef;
  border-bottom-width: 1px;
  border-top-width: 5px;

  h2, h3 {
    display: inline;
    margin: 0;
    padding: 0;
  }

  h2 {
    font-size: 0.9em;
    font-weight: bold;
  }

  h3 {
    margin-left: 0.75em;
    font-size: 0.7em;
  }
}


//////////////////////////////  404  //////////////////////////////

body.four-oh-four {
  .error-code {
    margin: 0 auto;
    line-height: 120px;
    font-size: 2.2rem;
    width: 120px;
    height: 120px;
    border-radius: 50%;
    border: 1px solid rgba(0, 0, 0, 0.1);
    background: #efefef;
  }

  .go-home {
    text-align: center;

    a {
      display: inline-block;
      border-radius: 50%;
      width: 70px;
      height: 70px;
      line-height: 70px;
      background: #f2f2f2;
      text-transform: uppercase;
      color: #777;
      font-size: 0.8rem;

      transition-duration: 0.1s;
      transition-property: background, color;

      &:hover {
        background: #e9e9e9;
        color: #555;
      }
    }
  }
}

//////////////////////////////  HOME  //////////////////////////////

body.home, body.archives {
  .post {
    .published {
      display: block;
      color: #999;
      font-size: 0.8em;
      text-transform: uppercase;
    }
    .title {
      font-size: 1.2rem;
      text-decoration: none;
      color: #444;
    }
    .excerpt {
      text-align: justify;
      color: #999;
    }
  }
}

body.home {
  .pagination {
    text-align: center;
    color: #aaa;

    .all-posts {
      margin-top: 50px;
      font-size: 0.7em;
      color: #ccc;
    }

    .pager {
      font-size: 0.8em;
    }
  }
}


//////////////////////////////  ARCHIVES  //////////////////////////////

body.archives {
  .post {
    .title {
      line-height: 1.4rem;
    }

    .published {
      line-height: 1;
      margin-right: 1em;
      vertical-align: bottom;
    }
  }

  > .pure-g {
    .title {
      padding: 2em 0;
      text-align: center;

      h1 {
        line-height: 1.25em;
        font-weight: normal;
      }
    }

    hr {
      margin-top: 0;
      margin-bottom: 1.5em;
    }
  }


}


//////////////////////////////  SINGLE POST  //////////////////////////////

body.post {
  .title {
    padding: 4em 0;
    text-align: center;

    h1 {
      line-height: 1.25em;
      font-weight: normal;
    }
  }

  .published {
    color: $secondary_text_color;
    text-transform: uppercase;
    font-size: 0.8em;
  }

  .share {
    text-align: center;

    > div {
      display: inline-block;
      font-size: 80%;
    }
  }

  .content {
    figure {
      margin-left: 0;
      margin-right: 0;
    }

    pre {
      font-size: 0.9em;
      line-height: 1.2em;
      margin: 1.5em 0;
      padding: 1em;
      border: 1px solid #f0f0f0;
      border-width: 1px 0;
      broder-radius: 3px;
      background: #f9f9f9;
      color: #333;
    }

    pre, pre code {
      font-family: Inconsolata, monospace;
    }

    code.highlighter-rouge {
      background: #f9f9f9;
      color: darkred;
      font-size: 0.9em;
    }

    p > img:only-child {
      margin-left: auto;
      margin-right: auto;
      display: block;
      max-width: 100%;
      border: 1px solid #efefef;
      box-sizing: border-box;
    }
  }

  hr {
    //@include fancy-hr(#fff, #ddd, #f2f2f2);

    &.comment-separator {
      margin: 4em 0;
    }
  }
}
